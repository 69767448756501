import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"


const IndexPage = ({ data }) => {
  return (
        <Layout>
        <Seo
          pagetitle="ページが見つかりません"
        />
       <div>
        <div className="mainTtlBg">
          <div className="mainTtlArea container">
            <h1>ページが見つかりません<span>NOT FOUND</span></h1>
          </div>{/* /mainphArea */}
        </div>{/* /mainphAreabg */}
      {/* パン屑 start */}
      <div className="breadArea">
        <ul className="breadLt container clearfix" itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to="https://jamstack-sg.com/" itemProp="item"><span itemProp="name">ヘッドレスCMS特設サイト</span></Link>
            <meta itemProp="position" content={1} />
          </li>
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <span itemProp="name">ページが見つかりません</span>
            <meta itemProp="position" content={2} />
          </li>
        </ul>
      </div>
      {/* パン屑 end */}
        <section>
          <div className="containerS">
            <p className="justTxt pb20">
              指定されたページは、このサーバ上に存在しません。<br />
              他の場所に移動したか、または削除された可能性があります。<br className="pcView" />
              ご指定のURLにタイプミスがないかご確認ください。<br />
            </p>
            <p className="justTxt pb40">
              上のメニューから目的のカテゴリを選んで移動するか、<br className="pcView" />
              以下のURLかヘッドレスCMS 制作・構築 特設サイトのトップページへ移動出来ます。<br />
            </p>
            <p className="pb80"><Link to={`/`}>https://www.jamstack-sg.com</Link></p>
          </div>{/* /containerS */}
        </section>
      </div>

        </Layout>
  )
}



export default IndexPage
